import React from 'react';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from '../../../Routes';
import { Link, useParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from 'components/Button';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import TextField from 'components/TextField';
import { useForm } from 'react-hook-form';
import RichTextEditorField from 'components/RichTextEditorField';
import {
  getEscalationCompany,
  getEscalationUsers,
  updateEscalationCompany,
} from 'data/api/company.api';
import TextFieldWithMask from 'components/TextFieldWithMask';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import SelectV2 from 'components/SelectV2';
import { replaceFontFamily, validateEmails } from 'utils';

const formatPhoneNumber = (phoneNumberString?: string) => {
  if (phoneNumberString) {
    phoneNumberString = phoneNumberString.replace('+', '');
    const formattedPhoneNumber = phoneNumberString.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3',
    );
    return formattedPhoneNumber;
  }
  return '';
};

const CustomDivider = ({ text }: { text: string }) => {
  return (
    <Divider
      textAlign="left"
      sx={{
        ' :before': {
          width: '0 !important',
        },
        ' :after': {
          width: '100% !important',
        },
        '&::before, &::after': {
          borderColor: '#213B54',
        },
        color: '#213B54',
        fontWeight: 700,
        fontSize: '14px',
        '& .MuiDivider-wrapper': {
          paddingLeft: '0px',
        },
      }}
    >
      {text}
    </Divider>
  );
};

export interface EscalationForm {
  companyPhone: string;
  generalEmail: string;
  googleReviewLink: string;
  clinicalContact: string;
  clinicalTriageNotes?: string;
  psychosocialContact: string;
  psychosocialTriageNotes?: string;
}

export const CompanyEscalationPage = () => {
  const { company_id } = useParams();
  const [escalationCompany, setEscalationCompany] = React.useState<any>();
  const [escalationUser, setEscalationUser] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [edit, setEdit] = React.useState(false);
  const { handleSubmit, reset, control, clearErrors } = useForm<EscalationForm>(
    {
      mode: 'onChange',
    },
  );

  const resetForm = () => {
    reset({
      companyPhone: escalationCompany?.companyPhone,
      generalEmail: escalationCompany?.generalEmail,
      googleReviewLink: escalationCompany?.googleReviewLink,
      clinicalContact: escalationCompany?.clinicalContact,
      clinicalTriageNotes: escalationCompany?.clinicalTriageNotes,
      psychosocialContact: escalationCompany?.psychosocialContact,
      psychosocialTriageNotes: escalationCompany?.psychosocialTriageNotes,
    });
    clearErrors();
  };

  const handleEditEscalation = async (values: EscalationForm) => {
    try {
      const res = await updateEscalationCompany(
        escalationCompany?.companyId || '',
        {
          ...values,
          companyPhone: values?.companyPhone?.replaceAll(/\D/g, ''),
        },
      );
      if (res.success) {
        setEdit(!res.success);
        fetchCompanyEscalation();
        toast.success('Update success!');
      } else {
        toast.error('Update failed!');
      }
    } catch (error) {
      toast.error('Update failed!');
    }
  };

  const fetchCompanyEscalation = async () => {
    try {
      const response = await getEscalationCompany(
        escalationCompany?.companyId || company_id || '',
      );
      setEscalationCompany(response.company);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserEscalation = async () => {
    try {
      const response = await getEscalationUsers(
        escalationCompany?.companyId || company_id || '',
      );
      setEscalationUser(response.users);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    resetForm();
    if (escalationCompany) {
      setLoading(false);
    }
  }, [escalationCompany]);

  React.useEffect(() => {
    fetchCompanyEscalation();
    fetchUserEscalation();
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          backgroundColor: 'transparent',
          color: (theme: any) => theme.palette.primary.main,
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeaderPage
        title={{
          title: escalationCompany?.name ? escalationCompany?.name : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_DETAIL + company_id,
          },
          {
            title: 'Users',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_EMPLOYEES(company_id),
          },
          {
            title: 'Escalation Protocol',
            selected: true,
            link: DASHBOARD_ROUTES.COMPANY_ID_ESCALATION(company_id),
          },
          {
            title: 'Company Notes',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_NOTES(company_id),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.COMPANY_ID_TRACKING(company_id),
          },
        ]}
      />

      {escalationCompany && (
        <div className="nv-container">
          <div className="section employees mb-6">
            <form onSubmit={handleSubmit(handleEditEscalation)} noValidate>
              <Stack className="w-[70%]" gap={1.5}>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Throughout our conversations with members, Betterleave will
                  use the following information and escalation guidelines to
                  triage inquiries to the appropriate customer team. Please
                  ensure that the information and escalation contacts are up to
                  date to support a smooth transition and timely response.
                </Typography>
                <Stack
                  sx={{
                    marginLeft: 'auto',
                  }}
                  direction={'row'}
                  gap={1}
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px',
                      width: '76px',
                      fontSize: '14px',
                      fontWeight: 700,
                      display: edit ? 'none' : 'block',
                    }}
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      height: '40px',
                      width: '110px',
                      fontSize: '14px',
                      fontWeight: 700,
                      display: edit ? 'block' : 'none',
                    }}
                    onClick={() => {
                      setEdit(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px',
                      width: '110px',
                      fontSize: '14px',
                      fontWeight: 700,
                      display: edit ? 'block' : 'none',
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Stack>
                <CustomDivider text="Organization Details" />
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Betterleave may share organization information with your
                  members throughout our communication.
                </Typography>
                {edit ? (
                  <Stack gap={2} width={'50%'}>
                    <TextFieldWithMask
                      label="24/7 Business Line"
                      name="companyPhone"
                      format="(###) ### ####"
                      control={control}
                      required
                      placeholder="24/7 Business Line"
                      rules={{
                        required: '24/7 Business Line is required.',
                      }}
                    />

                    <TextField
                      label="General Email"
                      name="generalEmail"
                      control={control}
                      required
                      placeholder="General Email"
                      rules={{
                        validate: (value) => {
                          if (validateEmails(value)) return true;
                          return 'Please enter valid email';
                        },
                        required: 'General Email is required.',
                      }}
                    />
                    <TextField
                      label="Google Review Link"
                      name="googleReviewLink"
                      control={control}
                      required
                      placeholder="Google Review Link"
                      rules={{
                        required: 'Google Review Link is required.',
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <Tooltip
                        title={'Missing information'}
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        sx={{
                          display: escalationCompany?.companyPhone
                            ? 'none'
                            : 'block',
                        }}
                      >
                        <IconButton>
                          <ReportProblemOutlinedIcon
                            sx={{
                              color: '#CA3838',
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                          marginLeft: escalationCompany?.companyPhone
                            ? '48px'
                            : '0px',
                        }}
                      >
                        24/7 Business Line:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {formatPhoneNumber(escalationCompany?.companyPhone)}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={1}
                      marginTop={
                        escalationCompany?.companyPhone &&
                        escalationCompany?.generalEmail
                          ? '10px'
                          : '0px'
                      }
                    >
                      <Tooltip
                        title={'Missing information'}
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        sx={{
                          display: escalationCompany?.generalEmail
                            ? 'none'
                            : 'block',
                        }}
                      >
                        <IconButton>
                          <ReportProblemOutlinedIcon
                            sx={{
                              color: '#CA3838',
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                          marginLeft: escalationCompany?.generalEmail
                            ? '48px'
                            : '0px',
                        }}
                      >
                        General Email:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {escalationCompany?.generalEmail}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={1}
                      marginTop={
                        escalationCompany?.googleReviewLink &&
                        escalationCompany?.generalEmail
                          ? '10px'
                          : '0px'
                      }
                    >
                      <Tooltip
                        title={'Missing information'}
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        sx={{
                          display: escalationCompany?.googleReviewLink
                            ? 'none'
                            : 'block',
                        }}
                      >
                        <IconButton>
                          <ReportProblemOutlinedIcon
                            sx={{
                              color: '#CA3838',
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                          marginLeft: escalationCompany?.googleReviewLink
                            ? '48px'
                            : '0px',
                        }}
                      >
                        Google Review Link:
                      </Typography>

                      <Link
                        to={escalationCompany?.googleReviewLink}
                        className="tab"
                        style={{
                          color: '#213B54',
                          fontSize: '13px',
                          fontWeight: 700,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {escalationCompany?.googleReviewLink}
                      </Link>
                    </Stack>
                  </Stack>
                )}

                <CustomDivider text="Clinical Triage Requests" />
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Betterleave will triage members to the administrator listed
                  below for requests or concerns that require clinical
                  expertise, scheduling assistance, or additional service
                  requests.
                </Typography>
                {edit ? (
                  <Stack gap={2}>
                    <Box width={'50%'}>
                      <SelectV2
                        label="Clinical Contact"
                        name="clinicalContact"
                        control={control}
                        required
                        variant="outlined"
                        rules={{
                          required: 'Clinical Contact is required.',
                        }}
                        data={
                          escalationUser
                            ? escalationUser.map((item: any) => {
                                return {
                                  value: item.userId,
                                  label: [item?.firstName, item?.lastName]
                                    .filter(Boolean)
                                    .join(' '),
                                };
                              })
                            : []
                        }
                      />
                    </Box>
                    <RichTextEditorField
                      name="clinicalTriageNotes"
                      label="Notes"
                      control={control}
                      placeholder="Add a note"
                      editorStyle={{
                        border: '1px solid #79747E',
                        borderRadius: '4px',
                        paddingRight: '12px',
                        paddingLeft: '12px',
                      }}
                      wrapperClassName="custom-editor"
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <Tooltip
                        title={'Missing information'}
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        sx={{
                          display: escalationCompany?.clinicalContact
                            ? 'none'
                            : 'block',
                        }}
                      >
                        <IconButton>
                          <ReportProblemOutlinedIcon
                            sx={{
                              color: '#CA3838',
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                          textAlign: 'center',
                          marginLeft: escalationCompany?.clinicalContactUser
                            ?.firstName
                            ? '48px'
                            : '0px',
                        }}
                      >
                        Clinical Contact:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {[
                          escalationCompany?.clinicalContactUser?.firstName,
                          escalationCompany?.clinicalContactUser?.lastName,
                        ]
                          .filter(Boolean)
                          .join(' ')}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      gap={1}
                      marginTop={
                        escalationCompany?.clinicalContact ? '10px' : '0px'
                      }
                      marginLeft={'48px'}
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                        }}
                      >
                        Email:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {escalationCompany?.clinicalContactUser?.email}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      gap={1}
                      marginTop={'10px'}
                      marginLeft={'48px'}
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                        }}
                      >
                        Phone:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {formatPhoneNumber(
                          escalationCompany?.clinicalContactUser?.phone,
                        )}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      gap={1}
                      marginTop={'10px'}
                      marginLeft={'48px'}
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                        }}
                      >
                        Notes:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                        }}
                      >
                        {parse(
                          DOMPurify.sanitize(
                            replaceFontFamily(
                              escalationCompany?.clinicalTriageNotes,
                            ),
                          ),
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                )}

                <CustomDivider text="Psychosocial Triage Requests" />
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  Betterleave will triage members to the administrator listed
                  below for requests or concerns related to emotional, social,
                  or family support.
                </Typography>
                {edit ? (
                  <Stack gap={2}>
                    <Box width={'50%'}>
                      <SelectV2
                        label="Psychosocial Contact"
                        name="psychosocialContact"
                        control={control}
                        required
                        rules={{
                          required: 'Psychosocial Contact is required.',
                        }}
                        data={
                          escalationUser
                            ? escalationUser.map((item: any) => {
                                return {
                                  value: item.userId,
                                  label: [item?.firstName, item?.lastName]
                                    .filter(Boolean)
                                    .join(' '),
                                };
                              })
                            : []
                        }
                      />
                    </Box>

                    <RichTextEditorField
                      name="psychosocialTriageNotes"
                      label="Notes"
                      control={control}
                      placeholder="Add a note"
                      editorStyle={{
                        border: '1px solid #79747E',
                        borderRadius: '4px',
                        paddingRight: '12px',
                        paddingLeft: '12px',
                      }}
                      wrapperClassName="custom-editor"
                    />
                  </Stack>
                ) : (
                  <Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      <Tooltip
                        title={'Missing information'}
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                        sx={{
                          display: escalationCompany?.psychosocialContact
                            ? 'none'
                            : 'block',
                        }}
                      >
                        <IconButton>
                          <ReportProblemOutlinedIcon
                            sx={{
                              color: '#CA3838',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                          marginLeft: escalationCompany?.psychosocialContactUser
                            ?.firstName
                            ? '48px'
                            : '0px',
                        }}
                      >
                        Psychosocial Contact:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {[
                          escalationCompany?.psychosocialContactUser?.firstName,
                          escalationCompany?.psychosocialContactUser?.lastName,
                        ]
                          .filter(Boolean)
                          .join(' ')}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      gap={1}
                      marginTop={
                        escalationCompany?.clinicalContact ? '10px' : '0px'
                      }
                      marginLeft={'48px'}
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                        }}
                      >
                        Email:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {escalationCompany?.psychosocialContactUser?.email}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      gap={1}
                      marginTop={'10px'}
                      marginLeft={'48px'}
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                        }}
                      >
                        Phone:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(33, 59, 84, 1)',
                        }}
                      >
                        {formatPhoneNumber(
                          escalationCompany?.psychosocialContactUser?.phone,
                        )}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      gap={1}
                      marginTop={'10px'}
                      marginLeft={'48px'}
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 700,
                          color: 'rgba(125, 129, 140, 1)',
                        }}
                      >
                        Notes:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                        }}
                      >
                        {parse(
                          DOMPurify.sanitize(
                            replaceFontFamily(
                              escalationCompany?.psychosocialTriageNotes,
                            ),
                          ),
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
