import { AxiosResponse } from 'axios';
import { API_ROUTES } from '../ApiRoutes';
import instance, { GET } from '../services/api';
import {
  NoteInfo,
  NoteTriageModal,
  NoteTriageModalEdit,
  NoteUserCreate,
  NoteUserUpdate,
  TriageOptions,
  UserNotes,
} from '../models/user-note.model';

export const getUserNotes = async (
  userId: string,
  includeAuto: boolean,
): Promise<UserNotes> => {
  const data: AxiosResponse<UserNotes> = await instance
    .get(API_ROUTES.adminUserNotes(userId) + `?includeAuto=${includeAuto}`)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const createUserNote = async (
  userId: string,
  payload: NoteUserCreate,
): Promise<NoteInfo> => {
  const data: AxiosResponse<NoteInfo> = await instance
    .post(API_ROUTES.adminUserNotes(userId), payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const updateUserNote = async (
  payload: NoteUserUpdate,
): Promise<NoteInfo> => {
  const data: AxiosResponse<NoteInfo> = await instance
    .put(API_ROUTES.adminUpdateUserNotes, payload)
    .catch((error) => {
      throw error;
    });

  return data.data;
};

export const getTriageOptions = async (): Promise<TriageOptions> => {
  const data: AxiosResponse<TriageOptions> = await instance
    .get(API_ROUTES.getTriageOptions)
    .catch((error) => {
      throw error;
    });
  return data.data;
};

export const createTriage = async (values: NoteTriageModal): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .post(API_ROUTES.createTriage, values)
    .catch((error) => {
      throw error;
    });
  return data.data;
};

export const updateTriage = async (
  values: NoteTriageModalEdit,
): Promise<any> => {
  const data: AxiosResponse<any> = await instance
    .put(API_ROUTES.updateTriage, values)
    .catch((error) => {
      throw error;
    });
  return data.data;
};

export const getAllNotes = async (userId: string) => {
  try {
    const data: AxiosResponse<any> = await GET(API_ROUTES.getAllNotes(userId));
    return data.data.events;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
