export interface UserNotes {
  notes: NoteInfo[];
}
export interface NoteInfo {
  userNoteId: string;
  note: string;
  createdByUsername: string;
  createdDate: string;
  isAutoGenerated: boolean;
  reason?: string;
  reasonId?: string;
  noteType?: string;

  triagesNotes?: TriagesNotesModel[];
  triagesStatus?: string;
  assignedToUserName?: string;
}

export interface TriagesNotesModel {
  triagesNotesId: string;
  triagesNotesLastModifiedDate?: string;
  triagesNotesLastModifiedByUserId?: string;
  isActive: string;
  createdDate: string;
  createdByUserId: string;
  note?: string;
  triagesId: string;
  triagesNotesCreatedByUserName?: string;
  triagesNotesLastModifiedByUserName?: string;
}

export interface NoteUserCreate {
  note: string;
}

export interface NoteUserUpdate {
  reasonId?: string;
  note?: string;
  userNoteId?: string;
}

export interface TriageOptions {
  triageOptions: TriageOption[];
}

export interface TriageOption {
  triageOptionsId: string;
  triageOptionsType: string;
}

export enum TriageType {
  New = 'New',
  InProgress = 'In Progress',
  Closed = 'Closed',
}
export interface NoteTriageModal {
  associatedToId: string;
  triagesType: string;
  assignedToId: string;
  triagesStatus: string;
  note: string;
}

export interface NoteTriageModalEdit extends NoteTriageModal {
  triagesId?: string;
  triageNoteId?: string;
}
