import React from 'react';
import { useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';
import { UserInfo } from 'data/models/user.model';
import { selectUser } from 'store/userSlide';
import { useSelector } from 'react-redux';
import { getEscalationUsers } from 'data/api/company.api';
import RichTextEditorField from 'components/RichTextEditorField';
import TextField from 'components/TextField';
import {
  createTriage,
  getTriageOptions,
  updateTriage,
} from 'data/api/user-note.api';
import {
  NoteTriageModal,
  NoteTriageModalEdit,
  TriageOption,
  TriageOptions,
  TriageType,
} from 'data/models/user-note.model';
import { toast } from 'react-toastify';
import ModalCustom from './Modal';

export type TModalAddTriageModal = {
  isModalAddTriage: boolean;
  setIsModalAddTriage: any;

  triageUpdate: NoteTriageModal | undefined;
  triagesId?: string;
  triagesNoteId: string;
};

const ModalAddTriageModal: React.FC<TModalAddTriageModal> = ({
  isModalAddTriage,
  setIsModalAddTriage,
  triageUpdate,
  triagesId,
  triagesNoteId,
}) => {
  const userInfo: UserInfo = useSelector(selectUser);
  const [escalationUser, setEscalationUser] = React.useState<any>();
  const [triageOptions, setTriageOptions] = React.useState<TriageOptions>();

  const { control, reset, clearErrors, handleSubmit } =
    useForm<NoteTriageModal>({
      reValidateMode: 'onChange',
      mode: 'onChange',
      defaultValues: {
        associatedToId: [userInfo?.firstName, userInfo?.lastName]
          .filter(Boolean)
          .join(' '),
        triagesType: '',
        assignedToId: '',
        triagesStatus: TriageType.New,
        note: '',
      },
    });

  const fetchTriageOptions = async () => {
    await getTriageOptions()
      .then((resp) => {
        setTriageOptions(resp);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchUserEscalation = async () => {
    try {
      const response = await getEscalationUsers(userInfo.companyId || '');
      setEscalationUser(response.users);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAllValid = async (values: NoteTriageModalEdit) => {
    try {
      if (triagesId) {
        await updateTriage({
          ...values,
          associatedToId: userInfo.userId || '',
          triagesId: triagesId,
          triageNoteId: triagesNoteId,
        });
        toast.success('Update Triage successfully!');
      } else {
        await createTriage({
          ...values,
          associatedToId: userInfo.userId || '',
        });
        toast.success('Create Triage successfully!');
      }
      setIsModalAddTriage(false);
      window.location.reload();
    } catch (error) {
      if (triagesId) {
        toast.error('Update Triage fail!');
      } else {
        toast.error('Create Triage fail!');
      }
    }
  };

  const resetForm = () => {
    reset({
      associatedToId: [userInfo.firstName, userInfo.lastName]
        .filter(Boolean)
        .join(' '),
      triagesType: triageUpdate?.triagesType || '',
      assignedToId: triageUpdate?.assignedToId || '',
      triagesStatus: triageUpdate?.triagesStatus || TriageType.New,
      note: triageUpdate?.note || '',
    });
    clearErrors();
  };

  React.useEffect(() => {
    if (userInfo && userInfo.userId) {
      fetchUserEscalation();
    }
    fetchTriageOptions();
    resetForm();
  }, [userInfo, triageUpdate]);

  return (
    <ModalCustom
      open={isModalAddTriage}
      title={'Add Triage'}
      groupButton={
        <form
          className="w-full"
          onSubmit={handleSubmit(handleAllValid)}
          noValidate
        >
          <Stack
            width={'644px'}
            sx={{
              justifyContent: 'space-between',
            }}
            spacing={2}
          >
            <Typography>
              Submit a triage request to notify the customer admin of a member
              need. Provide key details about the situation, including
              background, concerns, and any actions already taken.
            </Typography>

            <Stack
              sx={{
                width: '60%',
              }}
              spacing={2}
            >
              <TextField
                label="Associated to"
                name="associatedToId"
                variant="outlined"
                required
                control={control}
                rules={{
                  required: 'Associated to is required.',
                }}
                disabled
              />
              <SelectV2
                data={
                  triageOptions
                    ? triageOptions.triageOptions.map((item: TriageOption) => {
                        return {
                          value: item.triageOptionsId,
                          label: item.triageOptionsType,
                        };
                      })
                    : []
                }
                label="Type"
                name="triagesType"
                variant="outlined"
                required
                control={control}
                rules={{
                  required: 'Type is required.',
                }}
              />
              <SelectV2
                data={
                  escalationUser
                    ? escalationUser.map((item: any) => {
                        return {
                          value: item.userId,
                          label: [item?.firstName, item?.lastName]
                            .filter(Boolean)
                            .join(' '),
                        };
                      })
                    : []
                }
                label="Assigned To"
                name="assignedToId"
                variant="outlined"
                required
                control={control}
                rules={{
                  required: 'Assigned To is required.',
                }}
              />
              <SelectV2
                data={[
                  { label: TriageType.New, value: TriageType.New },
                  {
                    label: TriageType.InProgress,
                    value: TriageType.InProgress,
                  },
                  { label: TriageType.Closed, value: TriageType.Closed },
                ]}
                label="Status"
                name="triagesStatus"
                variant="outlined"
                required
                control={control}
                rules={{
                  required: 'Status is required.',
                }}
              />
            </Stack>

            <RichTextEditorField
              name="note"
              label="Comment"
              control={control}
              editorStyle={{
                border: '1px solid #79747E',
                borderRadius: '4px',
                paddingRight: '12px',
                paddingLeft: '12px',
              }}
              placeholder="Add a comment"
              wrapperClassName="custom-editor"
            />
            <Stack
              direction={'row'}
              sx={{
                width: '100%',
                justifyContent: 'end',
                marginTop: '23px',
              }}
              spacing={'12px'}
            >
              <Button
                onClick={() => {
                  setIsModalAddTriage(false);
                  resetForm();
                }}
                sx={{
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '97px',
                  height: '40px',
                  border: 'none',
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  fontWeight: 700,
                  fontSize: '14px',
                  width: '97px',
                  height: '40px',
                  lineHeight: '20px',
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </form>
      }
      subTittle={''}
      handleClose={() => {
        setIsModalAddTriage(false);
        resetForm();
      }}
      PaperProps={{
        sx: {
          width: '100%',
          borderRadius: 7,
          paddingX: 6,
          paddingTop: 4,
          paddingBottom: 3,
          maxWidth: 'fit-content !important',
        },
      }}
    />
  );
};
export default ModalAddTriageModal;
