import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { UserInfo } from '@data/models/user.model';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DASHBOARD_ROUTES } from 'Routes';
import { selectUser } from 'store/userSlide';
import {
  createUserNote,
  getUserNotes,
  updateUserNote,
} from 'data/api/user-note.api';
import {
  NoteInfo,
  NoteTriageModal,
  NoteUserCreate,
  NoteUserUpdate,
  TriagesNotesModel,
} from '@data/models/user-note.model';
import { Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CheckboxSingle from 'components/CheckboxSingle';
import moment from 'moment';
import TableTracking from 'components/Table/tableTracking';
import ModalConfirmActive from 'pages/user/components/ModalInactiveReasons';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ActionsUserNote from 'assets/icon-components/actions_user_note';
import ActionsUserNoteDisable from 'assets/icon-components/actions_user_note_disable';
import RichTextEditorField from 'components/RichTextEditorField';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import ModalAddTriageModal from './ModalAddTriage';
import { replaceFontFamily } from 'utils';
import ModalCustom from './ModalAddTriage/Modal';

const UserNotesNew = () => {
  const { user_id } = useParams();
  const userInfo: UserInfo = useSelector(selectUser);
  const [userNotesInfo, setUserNotesInfo] = React.useState<NoteInfo[]>([]);
  const [isActive, setIsActive] = React.useState(true);
  const [isModalInactive, setIsModalInactive] = React.useState<boolean>(false);
  const [isModalAddNote, setIsModalAddNote] = React.useState<boolean>(false);
  const [isModalAddTriage, setIsModalAddTriage] =
    React.useState<boolean>(false);

  const [userNoteUpdate, setUserNoteUpdate] =
    React.useState<NoteUserUpdate | null>();

  const [triageUpdate, setTriageUpdate] = React.useState<NoteTriageModal>();
  const [triagesId, setTriagesId] = React.useState<string>('');
  const [triagesNoteId, setTriagesNoteId] = React.useState<string>('');

  const { control, reset, clearErrors, handleSubmit } = useForm<NoteUserCreate>(
    {
      reValidateMode: 'onChange',
      mode: 'onChange',
      defaultValues: {
        note: userNoteUpdate?.note || '',
      },
    },
  );

  const resetForm = () => {
    reset({
      note: userNoteUpdate?.note || '',
    });
    clearErrors();
  };

  const fetchUserNotes = async (userId: string) => {
    await getUserNotes(userId, isActive)
      .then((resp) => {
        setUserNotesInfo(resp.notes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCreateUserNote = async (values: NoteUserCreate) => {
    try {
      if (user_id) {
        if (userNoteUpdate?.userNoteId) {
          await updateUserNote({
            userNoteId: userNoteUpdate.userNoteId,
            note: values.note,
          })
            .then(() => {
              toast.success('Update user note successfully!');
              window.location.reload();
            })
            .catch((error) => {
              toast.error(error);
              return;
            });
        } else {
          await createUserNote(user_id, { note: values.note })
            .then(() => {
              resetForm();
              toast.success('Create user note successfully!');
              window.location.reload();
            })
            .catch((error) => {
              console.error(error);
              toast.error('Create user note fail!');
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const messageRefs = React.useMemo(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    if (userNotesInfo)
      userNotesInfo?.forEach((item: any) => {
        if (item?.triagesNotes) {
          item?.triagesNotes?.forEach((note: TriagesNotesModel) => {
            refs[note?.triagesNotesId] = React.createRef<HTMLDivElement>();
          });
        }
      });
    return refs;
  }, [userNotesInfo]);

  const checkRefExistence = (item: TriagesNotesModel) => {
    if (item.triagesNotesId in messageRefs) {
      return messageRefs[item.triagesNotesId].current?.offsetHeight;
    } else {
      return 0;
    }
  };

  React.useEffect(() => {
    if (user_id) {
      void fetchUserNotes(user_id);
      resetForm();
    }
  }, []);

  React.useEffect(() => {
    resetForm();
  }, [userNoteUpdate]);

  const columns: GridColDef[] = [
    {
      field: 'isAutoGenerated',
      headerName: 'Auto',
      sortable: true,
      minWidth: 50,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.value && (
              <CheckIcon
                sx={{
                  color: 'rgba(33, 59, 84, 1)',
                }}
              />
            )}
          </>
        );
      },
    },
    {
      field: 'noteType',
      headerName: 'Type',
      flex: 0.5,
      sortable: true,
      minWidth: 170,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Typography
              fontWeight={500}
              lineHeight={'16px'}
              fontSize={'12px'}
              sx={{
                textWrap: 'wrap',
                textAlign: 'start',
                paddingTop: '8px',
                paddingBottom: '8px',
                color: 'rgba(0, 0, 0, 1)',
              }}
            >
              {params.value}
            </Typography>
          </>
        );
      },
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      sortable: true,
      minWidth: 280,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.assignedToUserName && (
              <Typography
                fontWeight={500}
                lineHeight={'16px'}
                fontSize={'12px'}
                sx={{
                  textWrap: 'wrap',
                  textAlign: 'start',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  color: 'rgba(0, 0, 0, 1)',
                }}
              >
                {params.row.assignedToUserName}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: 'triagesStatus',
      headerName: 'Status',
      sortable: true,
      minWidth: 80,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.triagesStatus && (
              <Typography
                fontWeight={500}
                lineHeight={'16px'}
                fontSize={'12px'}
                sx={{
                  textWrap: 'wrap',
                  textAlign: 'start',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  color: 'rgba(0, 0, 0, 1)',
                }}
              >
                {params.row.triagesStatus}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 2,
      sortable: true,
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.reason ? (
              <Stack paddingY={'8px'}>
                <Typography
                  fontWeight={500}
                  lineHeight={'16px'}
                  fontSize={'12px'}
                  sx={{
                    textWrap: 'wrap',
                    textAlign: 'start',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    color: '#000000',
                    fontSize: '12px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 700,
                      color: '#000000',
                      fontSize: '13px',
                    }}
                  >
                    Reason:
                  </span>
                  {params.row.reason}
                </Typography>
                <Typography
                  fontWeight={500}
                  lineHeight={'16px'}
                  fontSize={'12px'}
                  sx={{
                    textWrap: 'wrap',
                    textAlign: 'start',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    color: '#000000',
                  }}
                >
                  {parse(
                    DOMPurify.sanitize(
                      replaceFontFamily(params?.row?.note || ''),
                    ),
                  )}
                </Typography>
              </Stack>
            ) : (
              <>
                {!params.row.isAutoGenerated && (
                  <Stack
                    fontWeight={500}
                    fontSize={'12px'}
                    sx={{
                      textWrap: 'wrap',
                      textAlign: 'start',
                      color: '#000000',
                    }}
                    paddingY={'8px'}
                  >
                    {parse(
                      DOMPurify.sanitize(
                        replaceFontFamily(params?.row?.note || ''),
                      ),
                    )}
                  </Stack>
                )}
              </>
            )}
            {params.row.triagesNotes && params.row.triagesNotes.length > 0 && (
              <>
                <Stack height={'100%'} justifyContent={'center'}>
                  {params.row.triagesNotes.map((item: TriagesNotesModel) => {
                    return (
                      <Typography
                        key={item.triagesNotesId}
                        ref={messageRefs[item.triagesNotesId]}
                        sx={{
                          textWrap: 'wrap',
                          textAlign: 'start',
                          paddingTop: '8px',
                          paddingBottom: '8px',
                          color: 'rgba(0, 0, 0, 1)',
                          minHeight:
                            params.row.triagesNotes.length === 1
                              ? 'auto'
                              : checkRefExistence(item)
                              ? `${checkRefExistence(item)}px`
                              : '40px',
                        }}
                        fontSize={'12px'}
                      >
                        {parse(
                          DOMPurify.sanitize(
                            replaceFontFamily(item.note || ''),
                          ),
                        )}
                      </Typography>
                    );
                  })}
                </Stack>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'createdByUsername',
      headerName: 'Created By',
      flex: 1,
      sortable: true,
      minWidth: 140,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.triagesNotes && params.row.triagesNotes.length > 0 ? (
              <>
                <Stack height={'100%'} justifyContent={'center'}>
                  {params.row.triagesNotes.map((item: TriagesNotesModel) => {
                    return (
                      <Typography
                        key={item.triagesNotesId}
                        fontWeight={500}
                        lineHeight={'16px'}
                        fontSize={'12px'}
                        sx={{
                          textWrap: 'wrap',
                          textAlign: 'top',
                          paddingTop: '8px',
                          paddingBottom: '8px',
                          color: 'rgba(0, 0, 0, 1)',
                          minHeight:
                            params.row.triagesNotes.length === 1
                              ? 'auto'
                              : checkRefExistence(item)
                              ? `${checkRefExistence(item)}px`
                              : '40px',
                        }}
                      >
                        {item.triagesNotesCreatedByUserName}
                      </Typography>
                    );
                  })}
                </Stack>
              </>
            ) : (
              <>
                <Typography
                  fontWeight={500}
                  lineHeight={'16px'}
                  fontSize={'12px'}
                  sx={{
                    textWrap: 'wrap',
                    textAlign: 'start',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    color: 'rgba(0, 0, 0, 1)',
                  }}
                >
                  {params.value}
                </Typography>
              </>
            )}
          </>
        );
      },
    },

    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      sortable: true,
      minWidth: 180,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.triagesNotes && params.row.triagesNotes.length > 0 ? (
              <>
                <Stack height={'100%'} justifyContent={'center'}>
                  {params.row.triagesNotes.map((item: TriagesNotesModel) => {
                    return (
                      <Typography
                        key={item.triagesNotesId}
                        fontWeight={500}
                        lineHeight={'16px'}
                        fontSize={'12px'}
                        sx={{
                          display: '-webkit-box',
                          lineClamp: 2,
                          overflow: 'hidden',
                          color: '#000000',
                          whiteSpace: 'pre-wrap',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          paddingTop: '8px',
                          paddingBottom: '8px',
                          minHeight:
                            params.row.triagesNotes.length === 1
                              ? 'auto'
                              : checkRefExistence(item)
                              ? `${checkRefExistence(item)}px`
                              : '40px',
                          textAlign: 'top',
                        }}
                      >
                        {moment
                          .utc(item.createdDate)
                          .local()
                          .format('MM/DD/YYYY, hh:mm A')}
                      </Typography>
                    );
                  })}
                </Stack>
              </>
            ) : (
              <>
                <Typography
                  fontWeight={500}
                  lineHeight={'16px'}
                  fontSize={'12px'}
                  sx={{
                    display: '-webkit-box',
                    lineClamp: 2,
                    overflow: 'hidden',
                    color: '#000000',
                    whiteSpace: 'pre-wrap',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {moment
                    .utc(params.value)
                    .local()
                    .format('MM/DD/YYYY, hh:mm A')}
                </Typography>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      sortable: true,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.triagesNotes && params.row.triagesNotes.length > 0 ? (
              <>
                <Stack justifyContent={'center'}>
                  {params.row.triagesNotes.map((item: TriagesNotesModel) => {
                    return (
                      <Stack
                        key={item.triagesNotesId}
                        sx={{
                          minHeight:
                            params.row.triagesNotes.length === 1
                              ? 'auto'
                              : checkRefExistence(item)
                              ? `${checkRefExistence(item)}px`
                              : '40px',
                          alignItems: 'start',
                        }}
                      >
                        <Button
                          sx={{
                            height: 'fit-content !important',
                            paddingY: '8px !important',

                            alignItems: 'start',
                          }}
                          onClick={() => {
                            setTriageUpdate({
                              associatedToId:
                                params.row?.associatedToIdUser?.userId || '',
                              triagesType: params.row?.triagesType,
                              assignedToId: params.row?.assignedToId,
                              triagesStatus: params.row?.triagesStatus,
                              note: item?.note || '',
                            });
                            setTriagesId(params.row.triagesId);
                            setTriagesNoteId(item.triagesNotesId);
                            setIsModalAddTriage(true);
                          }}
                        >
                          <ActionsUserNote />
                        </Button>
                      </Stack>
                    );
                  })}
                </Stack>
              </>
            ) : params.row.isAutoGenerated ? (
              <Button>
                <ActionsUserNoteDisable />
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (params.row.noteType === 'Note') {
                    setIsModalAddNote(true);
                  }
                  if (params.row.noteType === 'Inactive Member') {
                    setIsModalInactive(true);
                  }
                  setUserNoteUpdate({
                    note: params.row.note,
                    reasonId: params.row.reasonId,
                    userNoteId: params.row.userNoteId,
                  });
                }}
              >
                <ActionsUserNote />
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
          {
            title: 'Conversations',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CONVERSATION(userInfo.userId),
          },
        ]}
      />
      <Stack
        sx={{
          width: '100%',
          paddingLeft: '120px',
          paddingRight: '60px',
          overflow: 'hidden',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          marginY={'12px'}
        >
          <CheckboxSingle
            checked={isActive}
            onChange={(event) => {
              setIsActive(event.target.checked);
            }}
            label="Include notes that were created automatically"
          />
          <Stack direction={'row'} gap={1}>
            <Button
              variant="contained"
              sx={{
                height: '40px',
                width: '114px',
                fontSize: '14px',
                fontWeight: 700,
              }}
              onClick={() => {
                setIsModalAddTriage(true);
                setTriageUpdate(undefined);
              }}
            >
              Add Triage
            </Button>
            <Button
              variant="contained"
              sx={{
                height: '40px',
                width: '114px',
                fontSize: '14px',
                fontWeight: 700,
              }}
              onClick={() => {
                setIsModalAddNote(true);
              }}
            >
              Add Note
            </Button>
          </Stack>
        </Stack>
        <TableTracking
          getRowId={(row) => row.userNoteId}
          columns={columns}
          rows={userNotesInfo || []}
        />
      </Stack>
      <ModalConfirmActive
        openModalFeeling={isModalInactive}
        setOpenModalFeeling={setIsModalInactive}
        userData={undefined}
        userNoteData={userNoteUpdate || undefined}
        setUserNoteUpdate={setUserNoteUpdate}
      />
      <ModalCustom
        open={isModalAddNote}
        title={'Add Note'}
        groupButton={
          <form
            className="w-full"
            onSubmit={handleSubmit(handleCreateUserNote)}
            noValidate
          >
            <Stack
              width={'644px'}
              sx={{
                justifyContent: 'space-between',
                marginTop: '12px',
              }}
              spacing={1}
            >
              <RichTextEditorField
                name="note"
                label="Note"
                control={control}
                editorStyle={{
                  border: '1px solid #79747E',
                  borderRadius: '4px',
                  paddingRight: '12px',
                  paddingLeft: '12px',
                }}
                placeholder="Add a note"
                wrapperClassName="custom-editor"
              />
              <Stack
                direction={'row'}
                sx={{
                  width: '100%',
                  justifyContent: 'end',
                }}
                spacing={'12px'}
              >
                <Button
                  onClick={() => {
                    setIsModalAddNote(false);
                    setUserNoteUpdate(null);
                    resetForm();
                  }}
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '20px',
                    width: '97px',
                    height: '40px',
                    border: 'none',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    fontWeight: 700,
                    fontSize: '14px',
                    width: '97px',
                    height: '40px',
                    lineHeight: '20px',
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        }
        subTittle={''}
        handleClose={() => {
          setIsModalAddNote(false);
          setUserNoteUpdate(null);
          resetForm();
        }}
        PaperProps={{
          sx: {
            width: '100%',
            borderRadius: 7,
            padding: 6,
            maxWidth: 'fit-content !important',
          },
        }}
      />
      <ModalAddTriageModal
        isModalAddTriage={isModalAddTriage}
        setIsModalAddTriage={setIsModalAddTriage}
        triageUpdate={triageUpdate}
        triagesId={triagesId}
        triagesNoteId={triagesNoteId}
      />
    </>
  );
};

export default UserNotesNew;
